export function showAvg(field) {
  if (this.$route.query.examType == 1) {
    const enmu = {
      1: ["schoolAverageScore", "unionAverageScore", "classAverageScore"],
      2: ["schoolAverageScore", "classAverageScore"],
      3: ["classAverageScore"],
    };
    let currentFields =
      enmu[this.topData.publishStudentIsDisplayAverageScore] || [];
    return currentFields.includes(field);
  } else if (this.$route.query.examType != 1) {
    let arr = ["schoolAverageScore", "classAverageScore"];
    return arr.includes(field);
  }
  return false;
}
export function showRank(field) {
  let { roleId } = JSON.parse(localStorage.getItem("jzjx-userInfo")).content;
  // roleId 4 学生  5 老师
  if (this.$route.query.examType == 1) {
    if (this.topData.isSchoolDisplayReportClassRank != 1) {
      return false;
    }
    if (roleId == 4) {
      if (this.topData.publishStudentIsDisplayRank > 0) {
        const enmu = {
          1: ["classRank", "schoolRank", "unionRank"],
          2: ["classRank", "schoolRank"],
          3: ["classRank"],
        };
        let currentFields =
          enmu[this.topData.publishStudentIsDisplayRank] || [];
        return currentFields.includes(field);
      } else if (this.topData.publishStudentIsDisplayRank == 0) {
        return false;
      }
    }
    return true;
  } else {
    let arr = ["classRank", "schoolRank"];
    return arr.includes(field);
  }
}
export function showScoreRate(field) {
  if (this.$route.query.examType == 1) {
    const enmu = {
      1: ["classScoreRate", "gradeScoreRate", "scoreRate", "unionScoreRate"],
      2: ["classScoreRate", "gradeScoreRate", "scoreRate"],
      3: ["classScoreRate", "scoreRate"],
    };
    let currentFields =
      enmu[this.topData.publishStudentIsDisplayQuestionScoreRate] || [];
    return currentFields.includes(field);
  }
  return true;
}
export function showHighestScore(field) {
  if (this.$route.query.examType == 1) {
    const enmu = {
      1: ["gradeMaxScore", "unionMaxScore"],
      2: ["gradeMaxScore"],
    };
    let currentFields =
      enmu[this.topData.publishStudentIsDisplayQuestionHighestScore] || [];
    return currentFields.includes(field);
  }
  return true;
}
export function showCountRight(field) {
  if (this.$route.query.examType == 1) {
    const enmu = {
      1: ["gradeCountRight", "unionCountRight"],
      2: ["gradeCountRight"],
    };
    let currentFields =
      enmu[this.topData.publishStudentIsDisplayQuestionCountRight] || [];
    return currentFields.includes(field);
  } else {
    return field === "gradeCountRight";
  }
}
export function showPointScoreRate(field) {
  if (this.$route.query.examType == 1) {
    const enmu = {
      1: ["classScoreRate", "gradeScoreRate", "scoreRate", "unionScoreRate"],
      2: ["classScoreRate", "gradeScoreRate", "scoreRate"],
      3: ["classScoreRate", "scoreRate"],
    };
    let currentFields =
      enmu[this.topData.publishStudentIsDisplayKnowledgeScoreRate] || [];
    return currentFields.includes(field);
  }
  return true;
}
