<template>
  <div class="studentReport">
    <div class="topCard">
      <tag></tag>
      <div class="title-box">
        <div v-if="$route.query.ruleId > 0" class="new-exam-tag">新高考</div>
        <div class="title">
          {{ topData.name }}
          <div :class="['sub-tag', topData.type == 99 ? 'other' : 'general']">
            {{ getExamType(topData.type) }}
          </div>
        </div>
      </div>
      <div v-if="roleId === 4" class="back" @click="back">
        <i class="el-icon-arrow-left"></i>返回
      </div>
      <div class="sub-title">
        <div>
          {{ topData.examDate }}&nbsp;&nbsp;&nbsp;&nbsp;<span v-if="level == 2"
            >初</span
          ><span v-if="level == 3">高</span>{{ topData.year }}级
        </div>
      </div>
      <div class="model-title">{{ topData.studentName }} - 学生报告</div>
      <subject-tabs
        class="mt10"
        has-total
        :value="$route.query.subjectId"
        @change="getSubjectId"
      />
    </div>
    <template v-if="topData.year">
      <summary-component
        id="summary"
        :subject-id="subjectId"
        :top-data="topData"
        :has-level-segment="hasLevelSegment"
      />
      <subject-component id="subject" :top-data="topData" />
      <situation
        v-if="
          (topData.publishStudentIsDisplayHistoryData == 1 && examType == 1) ||
          examType != 1
        "
        id="situation"
        :top-data="topData"
        :subject-id="subjectId"
      />
      <div v-if="showSubjectTab() && topData.subjectKnowledges" class="card">
        <subject-tabs @change="getSubjectBySec" />
      </div>
      <srknowledge-component
        v-if="subjectIdSec && pointshow && showSrknowledge()"
        id="srknowledge"
        :top-data="topData"
        :subject-id="subjectIdSec"
      />
      <srquestion-component
        v-if="subjectIdSec && showSrquestion()"
        id="srquestion"
        :subject-name="subjectNameSec"
        :top-data="topData"
        :subject-id="subjectIdSec"
      />
    </template>
    <page-nav :menu-list="menuList" />
  </div>
</template>
<script>
import { examTypeOptions } from "@/core/util/constdata";
import subjectTabs from "./components/subjectTabs.vue";
import summaryComponent from "./components/summary.vue";
import tag from "../components/tag.vue";
import subjectComponent from "./components/subject.vue";
import situation from "./components/situation.vue";
import srknowledgeComponent from "./components/srknowledge.vue";
import srquestionComponent from "./components/srquestion.vue";
import pageNav from "../components/pageNav.vue";
import { getstudentreporttopinfo } from "@/core/api/academic/student";
import { Uniongetstudentreporttopinfo } from "@/core/api/academic/unionReport";
export default {
  name: "StudentReport",
  components: {
    tag,
    summaryComponent,
    subjectComponent,
    situation,
    subjectTabs,
    srknowledgeComponent,
    srquestionComponent,
    pageNav,
  },
  data() {
    return {
      subjectId: "",
      tabs: [],
      statId: this.$route.query.id,
      topData: { ...this.$route.query },
      subjectIdSec: "",
      subjectNameSec: "",
      level: this.$route.query.level,
      examType: this.$route.query.examType,
      menuList: [
        {
          name: "成绩概述",
          id: "summary",
        },
        {
          name: "优劣势学科",
          id: "subject",
        },
        {
          name: "学情跟踪",
          id: "situation",
        },
        {
          name: "知识点",
          id: "srknowledge",
        },
        {
          name: "题目应答情况",
          id: "srquestion",
        },
      ],
      roleId: {},
      pointshow: false,
      hasLevelSegment: null,
    };
  },
  mounted() {
    const { roleId } = JSON.parse(
      localStorage.getItem("jzjx-userInfo")
    ).content;
    this.roleId = roleId;
  },
  methods: {
    showSubjectTab() {
      if (this.examType == 1) {
        let { roleId } = JSON.parse(
          localStorage.getItem("jzjx-userInfo")
        ).content;
        if (roleId == 4) {
          return (
            this.topData.publishStudentIsDisplayQuestion == 1 ||
            this.topData.publishStudentIsDisplayKnowledge == 1
          );
        }
        return true;
      }
      return true;
    },
    showSrquestion() {
      let { roleId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      if (roleId == 4 && this.examType == 1) {
        return this.topData.publishStudentIsDisplayQuestion == 1;
      }
      return true;
    },
    showSrknowledge() {
      let { roleId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      if (roleId == 4 && this.examType == 1) {
        return this.topData.publishStudentIsDisplayKnowledge == 1;
      }
      return true;
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value == type);
        return data.label;
      }
    },
    async getstudentreporttopinfoData() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await Uniongetstudentreporttopinfo({
          id: this.statId,
          viewSchoolId: this.$route.query.schoolId,
          studentId: this.$route.query.studentId,
        });
      } else {
        res = await getstudentreporttopinfo({
          id: this.statId,
          studentId: this.$route.query.studentId,
        });
      }

      this.topData = res.data.data;
      this.filterMenuList();
    },
    getSubjectId({ subjectId, hasLevelSegment }) {
      this.subjectId = subjectId;
      this.hasLevelSegment = hasLevelSegment;
      this.getstudentreporttopinfoData();
    },
    subjectIdSecChange() {
      const { subjectKnowledges } = this.topData;
      const data = subjectKnowledges.find(
        (item) => item.subjectId == this.subjectIdSec
      );
      this.pointshow = data.hasKnowledge != 0;
      if (data && data.hasKnowledge == 0) {
        this.menuList = this.menuList.filter((item) => item.name !== "知识点");
      } else if (this.subjectIdSec == 0) {
        this.menuList = this.menuList.filter((item) => item.name !== "知识点");
      } else {
        this.menuList = [
          {
            name: "成绩概述",
            id: "summary",
          },
          {
            name: "优劣势学科",
            id: "subject",
          },
          {
            name: "学情跟踪",
            id: "situation",
          },
          {
            name: "知识点",
            id: "srknowledge",
          },
          {
            name: "题目应答情况",
            id: "srquestion",
          },
        ];
      }
      this.filterMenuList();
    },
    filterMenuList() {
      let { roleId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      if (roleId == 4 && this.examType == 1) {
        if (this.topData.publishStudentIsDisplayQuestion != 1) {
          this.menuList = this.menuList.filter(
            (item) => item.name !== "题目应答情况"
          );
        }
        if (this.topData.publishStudentIsDisplayKnowledge != 1) {
          this.menuList = this.menuList.filter(
            (item) => item.name !== "知识点"
          );
        }
      }
    },
    getSubjectBySec({ subjectId, subjectName }) {
      this.subjectIdSec = subjectId;

      this.subjectNameSec = subjectName;
      this.subjectIdSecChange();
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-table {
  ::v-deep .hover-row {
    td {
      background-color: #fff5d7 !important;
    }
  }
}
.studentReport {
  width: 1200px;
  margin: 0 auto;
  .subjects {
    margin-bottom: 24px;
    text-align: center;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
::v-deep .el-tabs__active-bar {
  display: none;
}
::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}
::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}

::v-deep .el-icon-arrow-left {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}
::v-deep .el-icon-arrow-right {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}
</style>
