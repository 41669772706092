<template>
  <div v-loading="loading" class="card">
    <div class="section-sub-title">题目应答情况</div>
    <div v-if="tableData.length">
      <bar-line-chart class="mt10" :data="barLineOptions" :height="500" />
      <exportBtn
        :request-func="requestFunc"
        :params="{
          id: $route.query.id,
          studentId: $route.query.studentId,
          type: 2,
        }"
      />
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        class="mt10"
        :cell-style="cellStyle"
      >
        <el-table-column prop="questionOrder" label="题目" align="center" />
        <el-table-column
          prop="score"
          label="满分分值"
          align="center"
          width="100"
        />
        <el-table-column
          prop="rightAnswer"
          width="100"
          label="正确答案"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.rightAnswer || "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="actualScore" label="得分" align="center" />
        <el-table-column
          prop="answer"
          label="学生答案"
          width="100"
          align="center"
        >
          <template slot-scope="{ row }">
            <span :class="row.isWrong == 1 ? 'ft-red' : ''">
              {{ row.answer || "-" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showScoreRate('scoreRate')"
          prop="scoreRate"
          label="得分率"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="showScoreRate('classScoreRate')"
          prop="classScoreRate"
          label="得分率（班级）"
          align="center"
          width="130px"
        />
        <el-table-column
          v-if="showScoreRate('gradeScoreRate')"
          prop="gradeScoreRate"
          :label="`得分率（${examType == 1 ? '学校' : '年级'}）`"
          align="center"
          width="130px"
        />
        <el-table-column
          v-if="examType == 1 && showScoreRate('unionScoreRate')"
          prop="unionScoreRate"
          label="得分率（整体）"
          align="center"
          width="130px"
        />
        <el-table-column
          v-if="showHighestScore('gradeMaxScore')"
          prop="gradeMaxScore"
          :label="`最高分（${examType == 1 ? '学校' : '年级'}）`"
          align="center"
          width="130px"
        />
        <el-table-column
          v-if="examType == 1 && showHighestScore('unionMaxScore')"
          prop="unionMaxScore"
          label="最高分（整体）"
          align="center"
          width="130px"
        />
        <el-table-column
          v-if="showCountRight('gradeCountRight')"
          prop="gradeCountRight"
          :label="`满分人数（${examType == 1 ? '学校' : '年级'}）`"
          align="center"
          width="150px"
        />
        <el-table-column
          v-if="examType == 1 && showCountRight('unionCountRight')"
          prop="unionCountRight"
          label="满分人数（整体）"
          align="center"
          width="150"
        />
        <el-table-column
          v-if="showPoints"
          prop="knowledgeNames"
          label="知识点"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.knowledgeNames || "-" }}
          </template>
        </el-table-column>
      </el-table>
      <div v-if="old.length > 12" class="btns">
        <el-button type="text" @click="isExtendChange">{{
          isExtend ? "收起" : "查看更多"
        }}</el-button>
      </div>
    </div>
    <no-data v-else></no-data>
    <div class="model-desc mt10">
      注：标红的表格表示学生得分率低于班级得分率
      <span v-if="examType == 1">（或校均分或整体均分）</span>
    </div>
  </div>
</template>
<script>
import {
  getsrquestion,
  exportStudentReport,
} from "@/core/api/academic/student";
import { Uniongetsrquestion } from "@/core/api/academic/unionReport";
import barLineChart from "./chart.vue";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import {
  showScoreRate,
  showHighestScore,
  showCountRight,
} from "./unionCommonFunc";
export default {
  name: "Srquestion",
  components: {
    barLineChart,
    exportBtn,
  },
  props: {
    subjectId: {
      type: [String, Number],
      default: null,
    },
    subjectName: {
      type: String,
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      requestFunc: exportStudentReport,
      barLineOptions: {},
      loading: false,
      tableData: [],
      scoreRate: [],
      gradeScoreRate: [],
      classScoreRate: [],
      showPoints: false,
      isExtend: false,
      old: [],
      showScoreRate: showScoreRate.bind(this),
      showHighestScore: showHighestScore.bind(this),
      showCountRight: showCountRight.bind(this),
      examType: this.$route.query.examType,
    };
  },
  watch: {
    subjectId: {
      handler() {
        this.$nextTick(() => {
          if (this.subjectId) {
            this.getsrquestionData();
          }
        });
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.subjectId) {
        this.getsrquestionData();
      }
    });
  },
  methods: {
    isExtendChange() {
      this.isExtend = !this.isExtend;
      this.tableData = this.isExtend ? this.old : this.old.slice(0, 12);
    },
    cellStyle({ columnIndex, row }) {
      if (columnIndex === 6 && row.classScoreRate > row.scoreRate) {
        return "background-color:#dc5b49 !important;color:#fff !important;";
      }
      if (columnIndex === 7 && row.gradeScoreRate > row.scoreRate) {
        return "background-color:#dc5b49 !important;color:#fff !important;";
      }
      if (this.$route.query.examType == 1) {
        if (columnIndex === 8 && row.unionScoreRate > row.scoreRate) {
          return "background-color:#dc5b49 !important;color:#fff !important;";
        }
      }
    },
    async getsrquestionData() {
      this.loading = true;
      try {
        let res;
        if (this.$route.query.examType == 1) {
          res = await Uniongetsrquestion({
            subjectId: this.subjectId,
            id: this.$route.query.id,
            studentId: this.$route.query.studentId,
            viewSchoolId: this.$route.query.schoolId,
          });
        } else {
          res = await getsrquestion({
            subjectId: this.subjectId,
            id: this.$route.query.id,
            studentId: this.$route.query.studentId,
          });
        }

        this.scoreRate = res.data.data.map((item) => item.scoreRate);
        this.gradeScoreRate = res.data.data.map((item) => item.gradeScoreRate);
        this.classScoreRate = res.data.data.map((item) => item.classScoreRate);
        this.tableData = res.data.data.slice(0, 12);
        // this.tableData = res.data.data;
        this.isExtend = false;
        this.old = res.data.data;
        this.scoreRate = [];
        this.gradeScoreRate = [];
        this.classScoreRate = [];
        this.showPoints = res.data.data.every((item) => item.knowledgeNames);
        let series = [
          {
            name: "得分率",
            type: "bar",
            barMaxWidth: 40,
            color: "#7AB5EF",
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                },
              },
            },
            data: res.data.data.map((item) => item.scoreRate),
          },
          {
            name: "班级",
            type: "line",
            color: "#A997FB",
            smooth: true,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#A897F9",
                  },
                },
              },
            },
            data: res.data.data.map((item) => item.classScoreRate),
          },
          {
            name: "年级",
            type: "line",
            color: "#FF9776",
            smooth: true,
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  position: "top",
                  textStyle: {
                    color: "#FF6833",
                  },
                },
              },
            },
            data: res.data.data.map((item) => item.gradeScoreRate),
          },
        ];
        if (
          this.$route.query.examType == 1 &&
          this.topData.isSchoolDisplayReportUnionAverageScore == 1
        ) {
          series.push({
            name: "整体",
            type: "line",
            color: "#F57574",
            smooth: true,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "#F57574",
                  },
                },
              },
            },
            data: res.data.data.map((item) => item.unionScoreRate),
          });
        }
        let { roleId } = JSON.parse(
          localStorage.getItem("jzjx-userInfo")
        ).content;

        if (this.$route.query.examType == 1 && roleId == 4) {
          if (this.topData.publishStudentIsDisplayQuestionScoreRate == 2) {
            series.splice(3, 1);
          } else if (
            this.topData.publishStudentIsDisplayQuestionScoreRate == 3
          ) {
            series.splice(2, 2);
          } else if (
            this.topData.publishStudentIsDisplayQuestionScoreRate == 0
          ) {
            series.splice(1, 3);
          }
        }
        this.barLineOptions = {
          title: {
            text: `图. ${this.subjectName}得分率统计`,
            textStyle: {
              fontWeight: "normal",
              fontSize: "14px",
            },
            left: "center",
          },
          dataZoom: [
            {
              show: res.data.data.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: res.data.data.length - (res.data.data.length - 25),
              xAxisIndex: [0],
              bottom: "5%",
              left: "center",
            },
          ],
          grid: {
            bottom: res.data.data.length > 25 ? "20%" : "5%",
            containLabel: true,
            top: "15%",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              shadowStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },

            valueFormatter: function (val) {
              return Math.abs(val);
            },
          },
          legend: {
            top: "5%",
          },
          color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
          xAxis: [
            {
              type: "category",
              data: res.data.data.map((item) => item.questionOrder),
              axisPointer: {
                type: "shadow",
              },
              axisLabel: {
                show: true,

                formatter: (val) => {
                  let arr = val.split("");
                  let index = 0;
                  let newArray = [];
                  while (index < arr.length) {
                    let data = arr.slice(index, (index += 5));
                    newArray.push(data.join(""));
                  }
                  let str = newArray.reduce(
                    (str, item) => str + "\n" + item,
                    ""
                  );
                  return str;
                },
                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "",
              axisLabel: {
                show: true,
                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
              },
            },
          ],
          series: [...series],
        };
        this.loading = false;
      } catch {
        this.loading = false;
        this.barLineOptions = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tag {
  background-color: #f2f8fe;
  box-sizing: border-box;
  padding: 24px;
  color: #737677;
  font-size: 14px;
  margin-top: 24px;
}

.el-table {
  ::v-deep .hover-row {
    td {
      background-color: #fff5d7 !important;
    }
  }
}

::v-deep .el-table__row {
  &:hover td {
    background-color: #fff5d7 !important;
  }
}

.btns {
  text-align: center;
  margin-top: 10px;
}
</style>
