<template>
  <el-tabs v-model="subjectId" @tab-click="handleChange">
    <el-tab-pane
      v-for="(item, index) in tabs"
      :key="index"
      :label="item.name"
      :name="item.id.toString()"
    ></el-tab-pane>
  </el-tabs>
</template>
<script>
import { getstatsubjectoption } from "@/core/api/academic/common";
export default {
  name: "SubjectTabs",
  props: {
    hasTotal: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tabs: [],
      subjectId: "",
      statId: this.$route.query.id,
    };
  },
  mounted() {
    this.getSubjectList();
  },
  methods: {
    handleChange() {
      const data = this.tabs.find((item) => item.id == this.subjectId);
      if (this.hasTotal) {
        this.$store.commit(
          "report/SET_ISMERGE",
          data.type === 2 || data.type === 3
        );
      }
      this.$emit("change", {
        subjectId: this.subjectId,
        subjectName: data.name,
        hasLevelSegment: data.hasLevelSegment,
      });
    },
    async getSubjectList() {
      const res = await getstatsubjectoption({
        id: this.statId,
        classNum: this.$route.query.classNum,
        hasTotal: this.hasTotal ? 1 : 0,
        requestPage: 6,
        viewSchoolId: this.$route.query.schoolId,
      });
      if (this.hasTotal) {
        this.tabs = res.data.data;
        this.$store.commit(
          "report/SET_ISMERGE",
          res.data.data[0].type === 2 || res.data.data[0].type === 3
        );
      } else {
        const data = res.data.data.filter((item) => {
          if (item.type !== 2 && item.type !== 3) {
            return item;
          }
        });
        this.tabs = data;
      }
      if (!this.value) {
        this.subjectId = this.tabs[0].id.toString();
      } else {
        let subjectIds = this.tabs.map((item) => item.id);
        const result = subjectIds.includes(this.value);
        this.subjectId = result ? this.value : subjectIds[0].toString();
      }
      this.handleChange();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
::v-deep .el-tabs__active-bar {
  display: none;
}
::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}
::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-icon-arrow-left {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}
::v-deep .el-icon-arrow-right {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}
</style>
