<template>
  <div
    ref="StudentReportSubject"
    v-lazyChart="{ fn: getsrweakData }"
    class="card"
  >
    <div class="section-title mb20">优劣势学科</div>
    <div class="content">
      <div class="chart">
        <chart v-if="chartOptions" :data="chartOptions" :height="300" />
        <no-data v-else></no-data>
      </div>
      <div class="board-box">
        <div class="warnSubject">
          <el-tooltip
            class="item"
            effect="dark"
            :content="stableSubject.toString()"
            :disabled="stableSubject.length === 0"
            placement="top"
          >
            <div class="subject ft-red">
              {{ stableSubject.toString() || "暂无" }}
            </div>
          </el-tooltip>

          <div class="text">稳定薄弱科目</div>
        </div>
        <div class="stableSubject">
          <el-tooltip
            class="item"
            :disabled="warnSubject.length === 0"
            effect="dark"
            :content="warnSubject.toString()"
            placement="top"
          >
            <div class="subject ft-blue">
              {{ warnSubject.toString() || "暂无" }}
            </div>
          </el-tooltip>

          <div class="text">需注意科目</div>
        </div>
      </div>
    </div>
    <div class="total">
      <span class="ft-blue">（共{{ examTotal }}次考试）</span>
    </div>
  </div>
</template>
<script>
import chart from "./chart.vue";
import { getsrweak } from "@/core/api/academic/student";
import { Uniongetsrweak } from "@/core/api/academic/unionReport";
import { getstatsubjectoption } from "@/core/api/academic/common";
export default {
  name: "StudentReportSubject",
  components: {
    chart,
  },
  data() {
    return {
      statId: this.$route.query.id,
      studentId: this.$route.query.studentId,
      chartOptions: {},
      stableSubject: [],
      warnSubject: [],
      subjects: [],
      examTotal: 0,
    };
  },
  methods: {
    async getsrweakData(loadingInstance, io) {
      const subjectRes = await getstatsubjectoption({
        id: this.statId,
        hasTotal: 0,
        requestPage: 6,
        viewSchoolId: this.$route.query.schoolId,
      });
      let roleSubjectList = subjectRes.data.data.map((item) => item.id);
      let res;
      if (this.$route.query.examType == 1) {
        res = await Uniongetsrweak({
          id: this.statId,
          studentId: this.studentId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getsrweak({
          id: this.statId,
          studentId: this.studentId,
        });
      }

      const { subjects, stableSubject, warnSubject, examTotal } = res.data.data;
      this.examTotal = examTotal;
      this.subjects = subjects;
      this.warnSubject = warnSubject;
      this.stableSubject = stableSubject;

      let data = subjects.filter(
        (item) =>
          roleSubjectList.includes(item.subjectId) &&
          (item.littleCount || item.seriousCount)
      );
      // littleCount 和 seriousCount 为0 为没有薄弱科目 和 需注意科目
      this.setChartOption(data);
      this.$nextTick(() => {
        loadingInstance.close();
        io.unobserve(this.$refs.StudentReportSubject);
      });
    },
    setChartOption(data) {
      if (data.length) {
        this.chartOptions = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: { itemGap: 30 },
          xAxis: {
            type: "value",
            position: "top",
            axisLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
          },
          yAxis: {
            type: "category",
            axisLine: { show: false },
            axisLabel: { show: true },
            axisTick: { show: false },
            splitLine: { show: false },
            data: data.map((item) => item.subjectName),
          },
          grid: {
            containLabel: true,
          },
          series: [
            {
              name: "严重偏科",
              color: "#FA8161",
              type: "bar",
              stack: "count",
              label: {
                show: true,
                position: "right",
                color: "inherit",
                fontSize: 9,
                formatter: function (data) {
                  if (data.value) {
                    return data.value;
                  } else return "";
                },
              },
              data: data.map((item) => item.seriousCount || 0),
            },
            {
              name: "稍薄弱",
              color: "#5B8FF9",
              type: "bar",
              stack: "count",
              label: {
                show: true,
                position: "right",
                color: "inherit",
                fontSize: 9,
                formatter: function (data) {
                  if (data.value) {
                    return data.value;
                  } else return "";
                },
              },
              data: data.map((item) => item.littleCount || 0),
            },
          ],
        };
      } else {
        this.chartOptions = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .chart {
    width: 650px;
  }
  .board-box {
    .stableSubject {
      width: 100%;
      color: #7ab5ef;
      background-color: #f4f9fe;
      height: 89px;
      text-align: center;
      width: 420px;
      padding: 20px;
      margin: 20px;
      text-align: center;
      .subject {
        font-size: 14px;
        line-height: 1.5;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      div {
        height: auto;
      }
    }
    .warnSubject {
      width: 100%;
      color: #fc8585;
      background-color: #fff5f5;
      text-align: center;
      height: 89px;
      width: 420px;
      padding: 20px;
      margin: 20px;
      text-align: center;

      .subject {
        font-size: 14px;
        line-height: 1.5;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      div {
        height: auto;
      }
    }
  }
}
.mb50 {
  margin-bottom: 50px;
}
.examNum {
  margin-bottom: 26px;
  color: #3f87f4;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.total {
  text-align: center;
  font-size: 18px;
}
</style>
