<template>
  <div class="card">
    <div class="section-title mb20">成绩概述</div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      class="mt10"
      style="width: 100%"
    >
      <el-table-column prop="subjectName" label="科目" align="center">
      </el-table-column>
      <el-table-column prop="classNum" label="班级" align="center">
      </el-table-column>
      <el-table-column
        v-isUnionShow="'publishSchoolIsAllowTeacherViewAnswerSheet'"
        prop="date"
        label="答题卡"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-button v-if="row.answerSheetUrl" type="text" @click="saw(row)">
            查看
          </el-button>
          <span v-if="!row.answerSheetUrl && row.subjectId != 0">无答题卡</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="showColumn('classRank') && showRank('classRank')"
        prop="classRank"
        label="班排名"
        align="center"
      >
      </el-table-column>
      <el-table-column
        v-if="showColumn('schoolRank') && showRank('schoolRank')"
        prop="schoolRank"
        label="校排名"
        align="center"
      >
      </el-table-column>
      <el-table-column
        v-if="$route.query.examType == 1 && showRank('unionRank')"
        key="unionRank"
        prop="unionRank"
        label="整体排名"
        align="center"
      >
      </el-table-column>
      <el-table-column
        key="weak"
        prop="weak"
        :label="subjectId == 0 ? '薄弱学科' : '薄弱情况'"
        align="center"
      >
        <template slot-scope="{ row }">
          <!-- weak 1稍薄弱 0 正常 2严重偏科 -->
          <span v-if="row.weak == 0"> - </span>
          <span v-if="row.weak == 1">稍薄弱 </span>
          <span v-if="row.weak == 2" class="ft-red">严重偏科</span>
        </template>
      </el-table-column>
      <el-table-column prop="score" label="得分" align="center">
        <template slot-scope="{ row }">
          {{ row.score === null ? "-" : row.score }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="hasLevelSegment == 1"
        prop="level"
        label="等级"
        align="center"
      >
      </el-table-column>
      <el-table-column
        v-if="showAvg('classAverageScore')"
        prop="classAverageScore"
        min-width="120"
        label="班级均分"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>
            <span>{{ row.classAverageScore }}</span>
            <span class="diff">
              <span v-if="getDiff(row.score, row.classAverageScore) > 0">+</span
              ><span
                :class="{ down: getDiffT(row.score, row.classAverageScore) }"
                >{{ getDiff(row.score, row.classAverageScore) }}</span
              >
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="showAvg('schoolAverageScore')"
        prop="schoolAverageScore"
        min-width="120"
        label="校均分"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>
            <span>{{ row.schoolAverageScore }}</span>
            <span class="diff">
              <span v-if="getDiff(row.score, row.schoolAverageScore) > 0"
                >+</span
              ><span
                :class="{ down: getDiffT(row.score, row.schoolAverageScore) }"
                >{{ getDiff(row.score, row.schoolAverageScore) }}</span
              >
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="examType == 1 && topData.publishStudentIsDisplayAverageScore == 1"
        prop="unionAverageScore"
        label="整体均分"
        width="120"
        align="center"
      >
      </el-table-column>
    </el-table>
    <div class="model-desc mt10">
      注：班级均分（或校均分<span v-if="examType == 1">或整体均分</span
      >）表格中的小字部分显示的是学生分数与班级均分 （或校均分<span
        v-if="examType == 1"
        >或整体均分</span
      >）的差值
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div v-for="(item, index) in imgList" :key="index">
        <img :src="item" style="width: 100%" alt="" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getsrscoreoverview } from "@/core/api/academic/student";
import { Uniongetsrscoreoverview } from "@/core/api/academic/unionReport";
import { showAvg, showRank } from "./unionCommonFunc";
export default {
  name: "Summary",
  props: {
    subjectId: {
      type: String,
      default: "",
    },
    hasLevelSegment: {
      type: Number,
      default: 0,
    },
    topData: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      tableData: [],
      statId: this.$route.query.id,
      studentId: this.$route.query.studentId,
      loading: false,
      dialogVisible: false,
      showAvg: showAvg.bind(this),
      showRank: showRank.bind(this),
      examType: this.$route.query.examType,
      imgList: [],
    };
  },
  watch: {
    subjectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getsrscoreoverviewData();
        }
      },
    },
  },
  methods: {
    showColumn(field) {
      if (this.tableData.length) {
        const result = this.tableData.every((item) => item[field] === null);
        return !result;
      }
    },
    getDiff(score, avg) {
      let diff = score - avg;
      if (Number(diff) == 0) {
        return "";
      }
      return diff.toFixed(2);
    },
    getDiffT(score, avg) {
      // let type=false
      let diff = score * 1000 - avg * 1000;
      return diff < 0 ? true : false;
    },
    async getsrscoreoverviewData() {
      try {
        this.loading = true;
        let res;
        if (this.$route.query.examType == 1) {
          res = await Uniongetsrscoreoverview({
            id: this.statId,
            studentId: this.studentId,
            subjectId: this.subjectId,
            viewSchoolId: this.$route.query.schoolId,
          });
        } else {
          res = await getsrscoreoverview({
            id: this.statId,
            studentId: this.studentId,
            subjectId: this.subjectId,
          });
        }

        this.loading = false;
        this.tableData = res.data.data;
      } catch {
        this.loading = false;
      }
    },
    saw(item) {
      if (item.answerSheetUrl) {
        sessionStorage.setItem("imgList", item.answerSheetUrl);
        const routeUrl = this.$router.resolve({
          path: "/academic/card",
          query: {
            name: item.subjectName,
            statId: this.statId,
            studentId: this.$route.query.studentId,
            subjectId: item.subjectId,
          },
        });
        window.open(routeUrl.href, "_blank");
      } else {
        this.$message.error("无可查看答题卡");
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table {
  ::v-deep .hover-row {
    td {
      background-color: #fff5d7 !important;
    }
  }
}
::v-deep .el-table__row {
  &:hover td {
    background-color: #fff5d7 !important;
  }
}
.tag {
  box-sizing: border-box;
  padding: 12px;
  background-color: #f2f8fe;
  color: #737677;
  font-size: 14px;
  margin: 24px auto;
  .row {
    margin-bottom: 12px;
  }
  & :last-child {
    margin-bottom: 0;
  }
}
.up {
  color: #67c23a;
}
.down {
  color: #fc7070;
}
.diff {
  font-size: 12px;
  color: #9999;
}
::v-deep .el-table__row {
  &:hover td {
    background-color: #fff5d7 !important;
  }
}
</style>
