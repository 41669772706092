<template>
  <div v-loading="loading" class="card">
    <div class="operate mb20">
      <div style="width: 88px"></div>
      <div class="section-title">学情跟踪</div>
      <div v-if="old.length" class="btn">
        <el-button type="primary" plain @click="chooseSchoool"
          >选择考试</el-button
        >
        <el-button type="text" :loading="exportLoading" @click="exportSheet"
          >导出报表</el-button
        >
      </div>
      <div v-else style="width: 88px"></div>
    </div>

    <div v-if="old.length" class="table mt10">
      <el-table ref="elTable" :data="tableData" border style="width: 100%">
        <el-table-column
          :key="Math.random()"
          prop="examDate"
          fixed
          label="时间"
          :width="subjects.length ? 124 : 'auto'"
          align="center"
        >
        </el-table-column>
        <el-table-column
          :key="Math.random()"
          prop="examName"
          label="考试名称"
          :width="subjects.length ? 224 : 'auto'"
          fixed
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="subjectId == 0"
          :key="Math.random()"
          fixed
          label="薄弱学科"
          show-overflow-tooltip
          width="150"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.littleSubject.length"
              >{{ row.littleSubject.toString() }}稍薄弱</span
            >
            <span v-if="row.seriousSubject.length"
              ><span class="ft-red">{{ row.seriousSubject.toString() }}</span
              >严重偏科</span
            >
          </template>
        </el-table-column>
        <template v-if="subjectId != 0">
          <el-table-column
            :key="Math.random()"
            prop="date"
            fixed
            :width="subjectId === 0 ? 307 : 'auto'"
            label="薄弱情况"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.weak == 0"> - </span>
              <span v-if="row.weak == 1"> 稍薄弱 </span>
              <span v-if="row.weak == 2" class="ft-red"> 严重偏科 </span>
            </template>
          </el-table-column>
        </template>
        <template v-if="subjectId == 0">
          <template v-for="(item, index) in subjects">
            <el-table-column
              :key="'score' + index"
              prop="score"
              :width="subjects.length > 3 ? 123 : 'auto'"
              :label="item.subjectName"
              align="center"
            >
              <template slot-scope="{ row }">
                <span>{{ row.subjects[index].score }}</span>
              </template>
            </el-table-column>
            <template v-if="$route.query.examType == 1">
              <el-table-column
                v-if="showRank('unionRank')"
                :key="'unionRank' + index"
                :width="subjectId == 0 && subjects.length > 3 ? 150 : 'auto'"
                prop="unionRank"
                label="整体排名"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.subjects[index].unionRank }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="showCol(subjects, 'schoolRank') && showRank('schoolRank')"
                :key="'schoolRank' + index"
                :width="subjectId == 0 && subjects.length > 3 ? 150 : 'auto'"
                prop="schoolRank"
                label="校排名"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.subjects[index].schoolRank }}</span>
                </template>
              </el-table-column>
            </template>

            <template v-else>
              <el-table-column
                v-if="showCol(subjects, 'rank') && showRank('classRank')"
                :key="'rank' + index"
                :width="subjectId == 0 && subjects.length > 3 ? 150 : 'auto'"
                prop="rank"
                :label="
                  item.subjectName == '总分'
                    ? '校排名'
                    : `${item.subjectName}-排名`
                "
                align="center"
              >
                <template slot-scope="{ row }">
                  <span>{{ row.subjects[index].rank }}</span>
                </template>
              </el-table-column></template
            >
          </template>
        </template>
        <el-table-column
          v-if="subjectId != 0"
          width="115"
          prop="score"
          label="得分"
          align="center"
        >
          <template slot-scope="{ row }">{{
            row.score === null ? "-" : row.score
          }}</template>
        </el-table-column>
        <template v-if="$route.query.examType == 1">
          <el-table-column
            v-if="subjectId != 0 && showRank('unionRank')"
            width="120"
            prop="unionRank"
            label="整体排名"
            align="center"
          >
          </el-table-column>
          <el-table-column
            v-if="
              subjectId != 0 &&
              showCol(tableData, 'schoolRank') &&
              showRank('unionRank')
            "
            width="115"
            prop="schoolRank"
            label="校排名"
            align="center"
          >
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column
            v-if="
              subjectId != 0 &&
              showCol(tableData, 'rank') &&
              showRank('classRank')
            "
            width="115"
            prop="rank"
            label="校排名"
            align="center"
          >
          </el-table-column>
        </template>
        <el-table-column
          v-if="subjectId != 0 && showRank('classRank')"
          width="115"
          prop="classRank"
          label="班级排名"
          align="center"
        >
        </el-table-column>
      </el-table>
      <div v-if="old.length > 5" class="btn">
        <el-button type="text" @click="isExtendChange">{{
          isExtend ? "收起" : "查看更多"
        }}</el-button>
      </div>
      <div v-if="subjectId == 0" class="title">图. 各学科击败率热度图</div>
      <div v-else class="title">图.{{ topData.studentName }}击败率跟踪曲线</div>
    </div>

    <no-data v-else></no-data>
    <!-- <heat-map
      v-if="subjectId == 0"
      :source="source"
      :item-height="80"
      :title="heatTitle"
      :show-visual-map="true"
    /> -->
    <chart :data="chartOtions" :height="500" />
    <!-- <div class="model-desc">注：热度图颜色越深，击败率越大。</div> -->
    <el-dialog
      title="选择考试"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <div class="row">
        考试类型：
        <el-select
          v-model="examType"
          multiple
          collapse-tags
          style="margin-left: 20px"
          placeholder="请选择"
          clearable
          @change="getstatcompare"
        >
          <el-option
            v-for="item in examTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <transfer-component
        ref="transferComponent"
        :key="transferKey"
        :value="examIds"
        :data="examList"
        :request-page="6"
        @change="getExamId"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getsrlearningtrack,
  exportStudentReport,
} from "@/core/api/academic/student";
import { Uniongetsrlearningtrack } from "@/core/api/academic/unionReport";
import transferComponent from "../../components/transferComponent.vue";
import { getSubjectList } from "@/core/util/util";
// import heatMap from "../../charts/heat.vue";
import { getsrlearningtrackstatlist } from "@/core/api/academic/common";
import { examTypeOptions } from "@/core/util/constdata";
import { showRank } from "./unionCommonFunc";
import chart from "./chart.vue";
export default {
  name: "Situation",
  components: {
    // heatMap,
    chart,
    transferComponent,
  },
  props: {
    subjectId: {
      type: String,
      default: "",
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const subjectList = getSubjectList();
    return {
      exportLoading: false,
      requestFunc: exportStudentReport,
      dialogVisible: false,
      examType: [],
      examTypeOptions: examTypeOptions,
      examList: [],
      examIds: [],
      statId: this.$route.query.id,
      studentId: this.$route.query.studentId,
      tableData: [],
      loading: false,
      subjects: [],
      heatOptions: {},

      source: {},
      subjectList: subjectList,
      chartOtions: {},
      heatTitle: "",
      old: [],
      isExtend: false,
      checkedExam: "",
      showRank: showRank.bind(this),
      transferKey: Math.random(),
      caches: [],
    };
  },
  watch: {
    subjectId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getsrlearningtrackData();
        }
      },
    },
  },
  methods: {
    async exportSheet() {
      try {
        this.exportLoading = true;
        const res = await exportStudentReport({
          id: this.$route.query.id,
          selectedIds:
            typeof this.checkedExam == "string"
              ? this.checkedExam
              : this.checkedExam.join(","),
          studentId: this.$route.query.studentId,
          type: 1,
        });
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: res.data.data.url,
            name: res.data.data.name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.exportLoading = false;
      } catch {
        this.exportLoading = false;
      }
    },
    submitDialog() {
      this.caches = JSON.parse(JSON.stringify(this.checkedExam));
      this.getsrlearningtrackData();
      this.dialogVisible = false;
    },
    showCol(arr, field) {
      if (arr.length) {
        const result = arr.every((item) => item[field] === null);
        return !result;
      }
      return false;
    },
    async getstatcompare() {
      const res = await getsrlearningtrackstatlist({
        statId: this.statId,
        studentId: this.$route.query.studentId,
        subjectId: this.subjectId,
        examTypes: this.examType.toString(),
        viewSchoolId:
          this.$route.query.examType == 1 ? this.$route.query.schoolId : null,
      });
      this.examList = res.data.data;
      if (!this.examIds.length) {
        this.examIds = res.data.data.map((item) => item.id).slice(0, 5);
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.checkedExam = JSON.parse(JSON.stringify(this.caches));
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    chooseSchoool() {
      this.dialogVisible = true;
      this.examIds = this.checkedExam.split(",").map(Number);
      // this.transferKey = Math.random();
      this.getstatcompare();
    },
    isExtendChange() {
      this.isExtend = !this.isExtend;
      this.tableData = this.isExtend ? this.old : this.old.slice(0, 5);
    },
    getSubjectName(subjectId) {
      const data = this.subjectList.find((item) => item.value == subjectId);
      if (data) {
        return data.subjectName;
      } else {
        return "";
      }
    },
    getExamId({ ids }) {
      this.checkedExam = ids;
    },
    async getsrlearningtrackData() {
      this.loading = true;
      let res;
      if (this.$route.query.examType == 1) {
        res = await Uniongetsrlearningtrack({
          subjectId: this.subjectId,
          statId: this.statId,
          current: 1,
          size: 5,
          selectedIds: this.checkedExam || "",
          studentId: this.studentId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getsrlearningtrack({
          subjectId: this.subjectId,
          statId: this.statId,
          current: 1,
          size: 5,
          selectedIds: this.checkedExam || "",
          studentId: this.studentId,
        });
      }

      this.loading = false;
      this.old = res.data.data;
      this.tableData = res.data.data.slice(0, 5);
      let reverseData = res.data.data.reverse();

      if (this.subjectId == 0) {
        if (res.data.data.length) {
          this.subjects = res.data.data[0].subjects;
        }
        const xAxis = reverseData.map((item) => item.examName);
        const subjects = reverseData.length ? reverseData[0].subjects : [];
        const arr = reverseData.flatMap((item) => item.subjects);

        const data = subjects.map((item) => {
          const same = arr.filter((it) => it.subjectName === item.subjectName);

          return {
            name: item.subjectName,
            type: "line",
            data: same.map((it) => it.scoreStd),
          };
        });
        this.chartOtions = {
          legend: {
            selected: {},
            type: "scroll",
            width: "80%",
          },
          tooltip: {
            backgroundColor: "rgba(0,0,0,0.7)",
            borderColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#fff",
            },
            trigger: "item",
            axisPointer: {
              type: "line",
              crossStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
            grid: {
              bottom: xAxis.length > 5 ? "10%" : "5%",
            },
            dataZoom: [
              //滚动条
              {
                show: xAxis.length > 5,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: xAxis.length - (xAxis.length - 5),
                xAxisIndex: [0],
                bottom: "10px",
                left: "center",
              },
            ],
            formatter: (params) => {
              let obj =
                params.name +
                "<br />" +
                params.marker +
                params.seriesName +
                "：" +
                params.value;
              return obj;
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: xAxis,
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
              formatter: (val) => {
                let arr = val.split("");
                let index = 0;
                let newArray = [];
                while (index < arr.length) {
                  let data = arr.slice(index, (index += 7));
                  newArray.push(data.join(""));
                }
                let str = newArray.reduce((str, item) => str + "\n" + item, "");
                return str;
              },
            },
            axisTick: {
              show: false,
            },
          },
          color: [
            "#6E94DB",
            "#FFC761",
            "#CE98E8",
            "#98DEE8",
            "#E89898",
            "#91AEFF",
          ],
          yAxis: {
            name: "击败率",
            type: "value",
          },
          series: [...data],
        };
        let selected = {};
        for (let i = 0; i < subjects.length; i++) {
          let key = subjects[i].subjectName;
          if (key !== "总分") {
            selected[key] = false;
          } else {
            selected[key] = true;
          }
        }
        this.chartOtions.legend.selected = selected;
      } else {
        const xAxis = reverseData.map((item) => item.examName);
        this.chartOtions = {
          tooltip: {
            backgroundColor: "rgba(0,0,0,0.7)",
            borderColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: "line",
              crossStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
            grid: {
              bottom: xAxis.length > 5 ? "10%" : "5%",
            },
            dataZoom: [
              //滚动条
              {
                show: xAxis.length > 5,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: xAxis.length - (xAxis.length - 5),
                xAxisIndex: [0],
                bottom: "10px",
                left: "center",
              },
            ],
            formatter: (params) => {
              let str = "";
              if (this.$route.query.examType == 1) {
                let isDisplayRank = this.topData.publishStudentIsDisplayRank;
                let rankStr =
                  ([1, 2, 3].includes(isDisplayRank)
                    ? "<br />" + "班级排名：" + params.data.classRank
                    : "") +
                  ([1, 2].includes(isDisplayRank)
                    ? "<br />" + "校排名：" + params.data.schoolRank
                    : "") +
                  (isDisplayRank == 1
                    ? "<br />" + "整体排名：" + params.data.unionRank
                    : "");

                str =
                  params.data.examName +
                    "<br />" +
                    "时间：" +
                    params.data.examDate +
                    "<br />" +
                    "分值：" +
                    params.data.scoreTotal +
                    "<br />" +
                    "得分：" +
                    params.data.score ===
                  0
                    ? "-"
                    : params.data.score +
                      rankStr +
                      "<br />" +
                      "击败率：" +
                      params.data.value;
              } else {
                str =
                  params.data.examName +
                    "<br />" +
                    "时间：" +
                    params.data.examDate +
                    "<br />" +
                    "分值：" +
                    params.data.scoreTotal +
                    "<br />" +
                    "得分：" +
                    params.data.score ===
                  0
                    ? "-"
                    : params.data.score +
                      "<br />" +
                      "班级排名：" +
                      params.data.classRank +
                      "<br />" +
                      "校排名：" +
                      params.data.rank +
                      "<br />" +
                      "击败率：" +
                      params.data.value;
              }

              return str;
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: xAxis,
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,

              formatter: (val) => {
                let arr = val.split("");
                let index = 0;
                let newArray = [];
                while (index < arr.length) {
                  let data = arr.slice(index, (index += 5));
                  newArray.push(data.join(""));
                }
                let str = newArray.reduce((str, item) => str + "\n" + item, "");
                return str;
              },
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
          },
          color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
          yAxis: {
            name: "击败率",
            type: "value",
          },
          series: [
            {
              data: reverseData.map((item) => {
                return {
                  ...item,
                  value: item.scoreStd || 0,
                };
              }),
              type: "line",
            },
          ],
        };
      }

      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  margin-bottom: 50px;
}

.tag {
  margin-top: 50px;
  box-sizing: border-box;
  padding: 24px;
  background-color: #f2f8fe;
  font-size: 14px;
  color: #737677;
}

.el-table {
  ::v-deep .hover-row {
    td {
      background-color: #fff5d7 !important;
    }
  }
}

::v-deep .el-table__row {
  &:hover td {
    background-color: #fff5d7 !important;
  }
}

.title {
  padding: 10px 0;
  font-size: 14px;
  text-align: center;
  color: #333;
}

.btn {
  margin-top: 10px;
  text-align: center;
}

.operate {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    float: right;
  }
}
</style>
