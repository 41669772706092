<template>
  <div v-loading="loaidng" class="card">
    <div class="section-sub-title">知识点</div>

    <radar-chart
      v-if="radarOptions"
      :data="radarOptions"
      :height="500"
      class="mt10"
    />
    <div v-if="tableData.length" class="mt10">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :cell-style="cellStyle"
      >
        <el-table-column prop="knowledgeName" label="知识点" align="center">
        </el-table-column>
        <el-table-column prop="score" label="满分值" align="center">
        </el-table-column>
        <el-table-column prop="actualScore" label="得分" align="center">
        </el-table-column>
        <el-table-column
          v-if="showPointScoreRate('scoreRate')"
          prop="scoreRate"
          label="得分率"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="showPointScoreRate('classScoreRate')"
          prop="classScoreRate"
          label="得分率（班级）"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="showPointScoreRate('gradeScoreRate')"
          prop="gradeScoreRate"
          label="得分率（年级）"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-if="
            $route.query.examType == 1 && showPointScoreRate('unionScoreRate')
          "
          prop="unionScoreRate"
          label="得分率（整体）"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="countQuestion" label="题量" align="center">
        </el-table-column>
        <el-table-column prop="questionOrders" label="题号" align="center">
        </el-table-column>
      </el-table>
    </div>
    <no-data v-else class="mt10"></no-data>
    <div class="model-desc mt10">
      注：标红的表格表示学生得分率低于班级得分率（或校得分率或整体得分率）；
    </div>
  </div>
</template>
<script>
import { getsrknowledge } from "@/core/api/academic/student";
import { Uniongetsrknowledge } from "@/core/api/academic/unionReport";

import radarChart from "./radar.vue";
import { mapState } from "vuex";
import { showPointScoreRate } from "./unionCommonFunc";
export default {
  name: "Srknowledge",
  components: {
    radarChart,
  },
  props: {
    subjectId: {
      type: [String, Number],
      default: null,
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      radarOptions: null,
      loaidng: false,
      tableData: [],
      classScoreRate: [],
      gradeScoreRate: [],
      scoreRate: [],
      showPointScoreRate: showPointScoreRate.bind(this),
      examType: this.$route.query.examType,
    };
  },
  computed: {
    ...mapState({
      isMerge: (state) => state.report.isMerge,
    }),
  },
  watch: {
    subjectId: {
      handler(val) {
        this.$nextTick(() => {
          if (val && !this.isMerge) {
            this.getsrknowledgeData();
          }
        });
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.subjectId) {
        this.getsrknowledgeData();
      }
    });
  },
  methods: {
    cellStyle({ columnIndex, row }) {
      if (columnIndex === 4 && row.classScoreRate > row.scoreRate) {
        return "background-color:#dc5b49 !important;color:#fff !important;";
      }
      if (columnIndex === 5 && row.gradeScoreRate > row.scoreRate) {
        return "background-color:#dc5b49 !important;color:#fff !important;";
      }
      if (this.$route.query.examType == 1) {
        if (columnIndex === 6 && row.unionScoreRate > row.scoreRate) {
          return "background-color:#dc5b49 !important;color:#fff !important;";
        }
      }
    },
    async getsrknowledgeData() {
      this.loaidng = true;
      this.classScoreRate = [];
      this.gradeScoreRate = [];
      this.scoreRate = [];
      let res;
      if (this.$route.query.examType == 1) {
        res = await Uniongetsrknowledge({
          subjectId: this.subjectId,
          id: this.$route.query.id,
          studentId: this.$route.query.studentId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getsrknowledge({
          subjectId: this.subjectId,
          id: this.$route.query.id,
          studentId: this.$route.query.studentId,
        });
      }

      this.loaidng = false;
      this.tableData = res.data.data;
      let points = res.data.data.map((item) => {
        return {
          name: item.knowledgeName,
          max: 100,
        };
      });

      if (points.length) {
        let dataList = [
          {
            name: "年级",
            data: res.data.data.map((item) => item.gradeScoreRate),
            color: "#7AB5EF",
            unit: "%",
          },
          {
            name: "班级",
            data: res.data.data.map((item) => item.classScoreRate),
            color: "#FF9776",
            unit: "%",
          },
          {
            name: "学生",
            data: res.data.data.map((item) => item.scoreRate),
            color: "#6EDBA7",
            unit: "%",
          },
        ];
        if (this.examType == 1) {
          if (this.topData.publishStudentIsDisplayKnowledgeScoreRate == 1) {
            dataList.push({
              name: "整体",
              data: res.data.data.map((item) => item.unionScoreRate),
              color: "#dc5b49",
              unit: "%",
            });
          } else if (
            this.topData.publishStudentIsDisplayKnowledgeScoreRate == 3
          ) {
            dataList = dataList.filter((item) => item.name !== "年级");
          } else if (
            this.topData.publishStudentIsDisplayKnowledgeScoreRate == 0
          ) {
            dataList = dataList.filter(
              (item) => item.name !== "年级" && item.name !== "班级"
            );
          }
        }

        this.radarOptions = {
          dataList: [...dataList],
          indicator: points,
        };
      } else {
        this.radarOptions = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tag {
  background-color: #f2f8fe;
  box-sizing: border-box;
  padding: 24px;
  color: #737677;
  font-size: 14px;
  margin-top: 24px;
}
.el-table {
  ::v-deep .hover-row {
    td {
      background-color: #fff5d7 !important;
    }
  }
}
::v-deep .el-table__row {
  &:hover td {
    background-color: #fff5d7 !important;
  }
}
</style>
