<template>
  <div ref="radar" class="radar" :style="{ height: `${height}px` }"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Radar",
  props: {
    data: {
      type: [Array, Object, null],
      default: null,
    },
    height: {
      type: Number,
      default: 350,
    },
  },
  data() {
    return {
      options: {
        legend: {
          data: ["年级", "班级", "学生"],
          itemHeight: 2,
          right: "10%",
          top: "center",
          orient: "vertical",
          itemWidth: 10,
          testStyle: {
            color: "#666",
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },
        },
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],

        radar: {},
        series: [
          {
            name: "班级得分率",
            type: "radar",
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    data(val) {
      this.initChart(val);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart(this.data);
    });
  },
  methods: {
    initChart({ dataList, indicator }) {
      const chart = echarts.init(this.$refs.radar, null, {
        devicePixelRatio: 2.5,
      });
      const buildSeries = function (dataIndex) {
        const data = dataList[dataIndex].data;
        const helper = data.map((item, index) => {
          const arr = new Array(data.length);
          arr.splice(index, 1, item);
          return arr;
        });
        return [data, ...helper].map((item, index) => {
          return {
            name: dataList[dataIndex].name,
            type: "radar",
            symbol: index === 0 ? "circle" : "none",
            symbolSize: 4,
            itemStyle: {
              color: "#fff",
            },
            lineStyle: {
              color: index === 0 ? dataList[dataIndex].color : "transparent",
            },
            areaStyle: {
              color: index === 0 ? dataList[dataIndex].color : "transparent",
              opacity: 0.5,
            },
            tooltip: {
              show: index === 0 ? false : true,
              formatter: () => {
                let res = indicator[index - 1].name + "：<br>";
                for (let x of dataList) {
                  let str =
                    '<i style="display: inline-block;width: 10px;height: 10px;background: ' +
                    x.color +
                    ';margin-right: 5px;border-radius: 50%;}"></i>' +
                    x.name +
                    "：" +
                    x.data[index - 1] +
                    x.unit +
                    "<br>";
                  res += str;
                }
                return res;
              },
            },
            z: index === 0 ? 1 : 2,
            data: [item],
          };
        });
      };
      const series = [];
      for (let i in dataList) {
        series.push(...buildSeries(i));
      }
      let option = {
        tooltip: {},
        radar: {
          indicator: indicator,
        },
        series: series,
      };
      chart.setOption(option, true);

      setTimeout(() => {
        setTimeout(() => {
          window.addEventListener("resize", () => {
            chart.resize();
          });
        }, 100);
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.radar {
  width: 100%;
  height: 450px;
}
</style>
